import * as React from 'react';
import Nav from './nav/nav';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './header.module.scss';

const Header = ({ useH1 }) => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}

				api {
					aboutUs: aboutUsSingleton {
						logoDark {
							path
							meta
							file {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
								}
							}
						}
					}
				}
			}
		`
	);
	const title = data?.site?.siteMetadata?.title;
	const logo = data?.api?.aboutUs?.logoDark;
	const logoImage = getImage(logo?.file);

	return (
		<header className={styles.header} data-navigation-id="header">
			<div className={styles.flexContainer}>
				<Link
					to="/"
					className={styles.siteTitle}
					style={{
						aspectRatio: (logoImage?.width / logoImage?.height)?.toString(),
					}}
					aria-label="Přejít na domovskou obrazovku"
				>
					<GatsbyImage
						title={logo?.meta?.title}
						alt={logo?.meta?.alt ?? 'Logo'}
						image={getImage(logoImage)}
						objectFit="contain"
						objectPosition="left"
						className={styles.logo}
					/>

					{useH1 ? <h1>{title}</h1> : <span className="as-h1">{title}</span>}
				</Link>

				<Nav />
			</div>
		</header>
	);
};

Header.defaultProps = {
	useH1: false,
};

Header.propTypes = {
	useH1: PropTypes.bool,
};

export default Header;
