export const PROJECTS_PER_PAGE = 12;

export const getNavItems = () => [
	{
		title: 'O Nás',
		url: '/o-nas/',
	},
	{
		title: 'Realizace',
		url: '/realizace/',
	},
	{
		title: 'Kontakt',
		url: '/#kontakt',
	},
];
