import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

function Seo({ description, meta, title, allowIndexing, allowFollowing }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
    `
	);

	const defaultTitle = site.siteMetadata?.title;
	const showOnlyDefaultTitle = title.length === 0 && defaultTitle;
	const htmlTitle = showOnlyDefaultTitle ? defaultTitle : title;
	const titleTemplate = !showOnlyDefaultTitle ? `%s | ${defaultTitle}` : `%s`;

	const metaDescription = description || site.siteMetadata?.description;

	return (
		<Helmet
			htmlAttributes={{
				lang: 'cs',
			}}
			title={htmlTitle}
			titleTemplate={titleTemplate}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: htmlTitle,
				},
				{
					property: 'og:description',
					content: metaDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:locale',
					content: 'cs',
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata?.author || '',
				},
				{
					name: 'twitter:title',
					content: htmlTitle,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
				{
					name: 'robots',
					content:
						(allowIndexing ? 'index, ' : 'noindex, ') + (allowFollowing ? 'follow' : 'nofollow'),
				},
			].concat(meta)}
		>
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap"
				rel="stylesheet"
			/>
			<link
				href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Roboto+Slab:wght@300;400;500&display=swap"
				rel="stylesheet"
			/>
		</Helmet>
	);
}

Seo.defaultProps = {
	meta: [],
	description: '',
	title: '',
	allowIndexing: true,
	allowFollowing: true,
};

Seo.propTypes = {
	description: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	allowIndexing: PropTypes.bool,
	allowFollowing: PropTypes.bool,
};

export default Seo;
