import * as React from 'react';
import PropTypes from 'prop-types';

import * as styles from './tag-list.module.scss';

const TagList = ({ tags }) => {
	return (
		<div className={styles.tagList}>
			<ul className={styles.flexContainer} aria-label="Tagy">
				{tags?.map((tag, i) => (
					<li key={`tag${i}`}>{tag}</li>
				))}
			</ul>
		</div>
	);
};

TagList.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagList;
