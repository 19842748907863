import * as React from 'react';
import { useEffect, useState } from 'react';
import { getNavItems } from '../../../config';
import { Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as styles from './nav.module.scss';

const Nav = ({ className }) => {
	const navItems = getNavItems();

	const [menuOpened, setMenuOpened] = useState(false);

	const onLinkClick = () => {
		setMenuOpened(false);
	};

	useEffect(() => {
		if (menuOpened) {
			document?.body?.classList?.add('no-scroll');
		} else {
			document?.body?.classList?.remove('no-scroll');
		}
	}, [menuOpened]);

	return (
		<nav
			className={classNames({
				[styles.nav]: true,
				[styles.menuOpened]: menuOpened,
				[className]: typeof className === 'string',
			})}
		>
			<button
				aria-label="Otevřít menu"
				aria-controls="menu-list"
				className={classNames({
					hamburger: true,
					'hamburger--spin': true,
					'is-active': menuOpened,
					[styles.menuToggle]: true,
				})}
				onClick={() => setMenuOpened(!menuOpened)}
			>
				<span className="hamburger-box">
					<span
						className={classNames({
							'hamburger-inner': true,
							[styles.hamburgerInner]: true,
						})}
					/>
				</span>
			</button>

			<div className={styles.menuList} id="menu-list">
				<ul className={styles.flexContainer}>
					{navItems?.map((item, i) => (
						<li key={`navItem${i}`} className={styles.menuItem}>
							<Link to={item?.url} onClick={() => onLinkClick()}>
								{item?.title}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</nav>
	);
};

Nav.propTypes = {
	className: PropTypes.string,
};

export default Nav;
