import * as React from 'react';
import Copyright from '../copyright/copyright';
import classNames from 'classnames';

import * as styles from './footer.module.scss';

const Footer = ({ className }) => {
	return (
		<footer
			className={classNames({
				[styles.footer]: true,
				[className]: className,
			})}
		>
			<Copyright />
		</footer>
	);
};

export default Footer;
