import * as React from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './project-category-list.module.scss';

const ProjectCategoryList = ({ projects, selectedCategory, onSelectionChange }) => {
	const [selected, setSelected] = useState(selectedCategory || null);
	const categories = useMemo(() => {
		return Array.from(
			new Set(
				projects
					?.map(project => project?.categories ?? [])
					?.reduce((acc, curr) => {
						acc.push(...curr);
						return acc;
					}, []) ?? []
			)
		);
	}, [projects]);

	const onButtonClick = category => {
		if (category !== selected) {
			setSelected(category);

			if (typeof onSelectionChange === 'function') {
				onSelectionChange(category);
			}
		}
	};

	return (
		<div className={styles.projectCategoryList}>
			<div className={styles.flexContainer}>
				<button
					className={classNames({
						'btn-border-dark': selected !== null,
						'btn-full-dark': selected === null,
						[styles.categoryBtn]: true,
					})}
					onClick={() => onButtonClick(null)}
				>
					Vše
				</button>

				{categories?.map((category, i) => (
					<button
						key={`projectCategory${i}`}
						className={classNames({
							'btn-border-dark': selected !== category,
							'btn-full-dark': selected === category,
							[styles.categoryBtn]: true,
						})}
						onClick={() => onButtonClick(category)}
					>
						{category}
					</button>
				))}
			</div>
		</div>
	);
};

ProjectCategoryList.defaultProps = {
	projects: [],
	selectedCategory: null,
	onSelectionChange: null,
};

ProjectCategoryList.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			categories: PropTypes.arrayOf(PropTypes.string),
		})
	),
	selectedCategory: PropTypes.string,
	onSelectionChange: PropTypes.func,
};

export default ProjectCategoryList;
