import * as React from 'react';
import { useMemo, useState } from 'react';
import MainLayout from '../layouts/main-layout/main-layout';
import { graphql } from 'gatsby';
import ProjectCategoryList from '../components/project-category-list/project-category-list';
import ProjectList from '../components/project-list/project-list';
import { PROJECTS_PER_PAGE } from '../config';
import classNames from 'classnames';

import * as styles from '../styles/pages/projects.module.scss';

const ProjectsPage = ({ data }) => {
	const [maxVisibleProjects, setMaxVisibleProjects] = useState(PROJECTS_PER_PAGE);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const projectsByCategory = useMemo(() => {
		return typeof selectedCategory === 'string'
			? data?.api?.projects?.filter(project => project.categories.includes(selectedCategory))
			: data?.api?.projects;
	}, [data, selectedCategory]);
	const visibleProjects = useMemo(() => {
		return projectsByCategory.slice(0, maxVisibleProjects);
	}, [projectsByCategory, maxVisibleProjects]);

	const onSelectedCategoryChange = category => {
		if (maxVisibleProjects !== PROJECTS_PER_PAGE) {
			setMaxVisibleProjects(PROJECTS_PER_PAGE);
		}

		setSelectedCategory(category);
	};

	return (
		<MainLayout
			seo={{
				title: 'Realizace',
				description: data?.api?.seo?.siteDescription,
			}}
		>
			<section className={styles.projectsSection}>
				<h1>Realizace</h1>

				<div className={styles.projectFilters}>
					<ProjectCategoryList
						projects={data?.api?.projects ?? []}
						onSelectionChange={category => onSelectedCategoryChange(category)}
					/>
				</div>

				<ProjectList projects={visibleProjects} />

				{projectsByCategory?.length > visibleProjects?.length && (
					<button
						className={classNames({
							'btn-border-dark': true,
							[styles.moreBtn]: true,
						})}
						onClick={() => setMaxVisibleProjects(maxVisibleProjects + PROJECTS_PER_PAGE)}
					>
						Zobrazit více{' '}
					</button>
				)}
			</section>
		</MainLayout>
	);
};

export const query = graphql`
	query {
		api {
			seo: seoSingleton {
				siteDescription
			}

			projects: projectsCollection(sort: { _o: 1 }) {
				title
				slug
				categories
				gallery {
					path
					meta
					file {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	}
`;

export default ProjectsPage;
